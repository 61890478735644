import ThemeProvider from '@mui/system/ThemeProvider'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import type { AppProps } from 'next/app'
import { theme } from '@/styles/theme'
import '@/styles/fonts.css'

const App = ({ Component, pageProps }: AppProps) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Container fixed>
      <Box paddingTop={`${theme.mixins.toolbar.minHeight}px`}>
        <Component {...pageProps} />
      </Box>
    </Container>
  </ThemeProvider>
)

export default App
