import { createTheme } from '@mui/material/styles'

const PRIMARY = '#3DC2F9'
const SECONDARY = '#EE8675'
const EIGENGRAU = '#16161d'

export const TOOLBAR_HEIGHT = 100

const mixins = {
  viewHeight: `calc(100vh - ${TOOLBAR_HEIGHT}px)`,
  toolbar: {
    minHeight: TOOLBAR_HEIGHT,
  },
}

export const theme = createTheme({
  mixins,
  palette: {
    mode: 'dark',
    primary: {
      main: PRIMARY,
    },
    secondary: {
      main: SECONDARY,
    },
    background: {
      paper: EIGENGRAU,
      default: EIGENGRAU,
    },
  },
  typography: {
    fontFamily: 'Gotham Rounded, Helvetica, sans-serif',
  },
})
